































import {Component, Vue} from 'vue-property-decorator';
import QrScanner from 'qr-scanner';
import QrScannerWorkerPath from '!!file-loader!qr-scanner/qr-scanner-worker.min.js';
import {UserProductDTO} from '@/generated';
import {getModule} from 'vuex-module-decorators';
import ProductModule from '@/store/modules/ProductModule';
import ProductSummary from '@/components/ProductSummary.vue';

QrScanner.WORKER_PATH = QrScannerWorkerPath;
@Component({
    components: {
        ProductSummary
    }
})
export default class Scanner extends Vue {
    private readonly productsModule = getModule(ProductModule, this.$store);
    private scanner!: QrScanner;

    flashAvailable: boolean = false;
    flashActive: boolean = false;
    scannedProduct: UserProductDTO | null = null;

    get flashButtonClass(): string {
        let out;
        if (this.flashActive) {
            out = 'btn-warning';
        } else {
            out = 'btn-outline-warning';
        }
        if (this.scannedProduct) {
            out += ' scanner__flash--increased';
        }
        return out;
    }

    async mounted() {
        if (!(this.$refs.scanner instanceof HTMLVideoElement)) {
            return;
        }
        this.scanner = new QrScanner(this.$refs.scanner, this.onScan.bind(this));
        this.scanner.setInversionMode('both');

        this.$once('hook:beforeDestroy', () => {
            this.scanner.destroy();
        });

        await this.scanner.start();

        this.flashAvailable = await this.scanner.hasFlash();
        this.flashActive = await this.scanner.isFlashOn();
    }

    async toggleFlash() {
        await this.scanner.toggleFlash();
        this.flashActive = this.scanner.isFlashOn();
    }

    onScan(result: string) {
        if (result === '') {
            return;
        }
        const product = this.productsModule.products.find(product => product.name === result);
        if (product) {
            this.scannedProduct = product;
        }
    }
}
