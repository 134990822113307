
























import {Component, Prop, Vue} from 'vue-property-decorator';
import {BrandDTO, CategoryDTO, UserProductDTO} from '@/generated';
import {Routes} from '@/router/Routes';
import ProductModule from '@/store/modules/ProductModule';
import {getModule} from 'vuex-module-decorators';

@Component
export default class ProductSummary extends Vue {
    @Prop() readonly product!: UserProductDTO;

    private readonly productModule = getModule(ProductModule, this.$store);

    category?: CategoryDTO;
    brand?: BrandDTO;
    routes = Routes;

    created() {
        this.category = this.productModule.categories.find(category => category.categoryId === this.product.categoryId);
        this.brand = this.productModule.brands.find(brand => brand.brandId === this.product.brandId);
    }

    getImagePath(image: string): string {
        return require(`@/assets/img/products/${image}`);
    }
}
